
import React from "react";
import logo2 from '../img/logo2.png'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaPhoneFlip } from "react-icons/fa6";

function Footer(){
  return (
    <div className="w-full bg-black p-20" id="contactUs">
      <div className="pb-10 flex lg:flex-row md:flex-col lg:justify-center lg:items-start flex-col justify-center items-center gap-6 text-gray-50">
        <div className="flex flex-col justify-start items-start gap-2 text-center">
            <img src={logo2} alt="" className="w-[160px] h-[100px]"/>
            <p className=" text-gray-50 font-primary lg:w-[40%] text-start " >مصنع رواسي الحديث للجرانيت والرخام هو مصنع متخصص  في تصنيع منتجات عالية الجودة من الجرانيت والرخام في الجمهورية اليمنية </p>
        </div>
        <div className=" flex flex-col justify-center items-start gap-4">
        <h4 className=" text-start text-primary font-primary font-bold text-xl my-4">معلومات التواصل </h4>
        <div className="flex flex-row justify-center items-start gap-3 ">
        <FaLocationDot className=" font-secondary text-xl"  style={{ color:'#F1CA31' }}/>
        <div className="flex flex-col justify-center items-start gap-3 ">
        <p className="font-primary  ">الجمهورية اليمنية صنعاء - الصباحة - مدخل لولوة</p>
        <p className="font-primary "> الجمهورية اليمنية صنعاء - جولة بيت بوس - جوار الجزيرة للدهانات</p>
      </div>
      </div>
      <div className="flex flex-row  gap-3 justify-center items-start">
      <FaPhoneFlip className="font-secondary text-lg " style={{ color:'#F1CA31' }} />
      <div className="flex flex-col justify-center items-center  gap-3">
    
     <span className="font-primary">9671526006+</span>
     <span className="font-primary">967780122522+</span>
     <span className="font-primary">967782888877+</span>
      </div>
      </div>
      <div className="flex gap-3 justify-center items-center">
      <MdEmail className="font-secondary text-xl" style={{  color:'#F1CA31' }} />
     <a href="o" className=" font-primary">
         info@rawasy-modern.com
      </a>
     </div>
        </div>
      </div>
      <hr className="p-6"></hr>
      <div className="flex lg:flex-row flex-col justify-between items-start gap-8 ">
        <div className="flex flex-col items-start justify-center gap-4">
          <div className="flex lg:flex-row flex-col gap-2 items-center justify-center">
            <a href="/" className="text-secondary font-primary font-bold">الرئيسية :: </a>
            <a href="/products" className="text-gray-50 font-primary font-bold"> المنتجات ::</a>
            <a href="/about" className="text-gray-50 font-primary font-bold">من نحن :: </a>
            <a href="/works" className="text-gray-50 font-primary font-bold">الأعمال :: </a>
            <a href="/contactUs" className="text-gray-50 font-primary font-bold"> تواصل معنا </a>
          </div>
          <p className=" text-gray-50 text-sm font-primary font-medium">Copyright@rawasy-modern All Right Reserved</p>
        </div>
        <div className="flex flex-col items-start justify-center gap-4">
          <h2 className=" text-secondary font-primary font-bold text-xl">يمكنك متابعتنا على </h2>
          <div className="flex justify-center items-center ml-20 gap-4 text-lg">
            <a href="https://www.facebook.com/profile.php?id=61553956433601&mibextid=ZbWKwL"><FaFacebookSquare  className="text-gray-50" /></a>
            <FaInstagramSquare className="text-gray-50"/>
            <FaSquareXTwitter className="text-gray-50" />
      </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
