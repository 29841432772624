
import { React, useState , useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Header from "../TopSection";
import Navbar from '../Navbar'
import Footer from "../footer";
import { FaPhoneVolume } from "react-icons/fa6";

function Products() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

      const addMessage = async (messageData) => {
        try {
          const response = await fetch(`${apiUrl}/api/messages/`, {
            method: "POST",
            body: JSON.stringify(messageData),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log("sub2");
          const data = await response.json();
          toast.success('تم الارسال بنجاح');
          setFormData({name:'' , phone:'' ,messageText:'' ,email:''})
          console.log(data)
        } catch (error) {
          console.error("Error adding user:", error);
        }
      };
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updateData = { ...formData, [name]: value };
        setFormData(updateData)
      };
      const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};
        const phoneRegex = /^\d{9}$/;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      
        if (!formData.name) {
          newErrors.name = 'يجب ادخال الاسم';
        }
            if(formData.email && !emailRegex.test(formData.email)){
              newErrors.email = 'ادخل ايميل صحيح';
            }
        
        if (!formData.messageText) {
          newErrors.messageText = 'يجب ادخال رسالتك';
        }
    
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
          addMessage(formData);
        }
      };
  return (
    <div>
        <Header />
        <Navbar/>
        <div className=" container py-20 ">
            <h1 data-aos="fade-up" className=" lg:text-4xl text-3xl font-primary font-bold text-center p-10 ">تواصل معنا </h1>
            <p  data-aos="fade-up" className=" text-lg font-primary text-center font-medium">يمكنك أن تتواصل معنا على : </p>
            <div data-aos="fade-up" className=" flex flex-col justify-center items-center gap-3">
                <div  className="flex flex-row justify-center items-center gap-4 ">
                <FaPhoneVolume className="font-secondary text-sm " />
                <span className="font-primary  text-md font-bold text-right">77 8888 782 967+</span>
                </div>
                <div className="flex flex-row justify-center items-center gap-4">
                <FaPhoneVolume className="font-secondary text-md " />
                <span className="font-primary  text-md font-bold text-right">22 1225 780 967+</span>
                </div>
            </div>
            <div  data-aos="fade-left" className=" flex lg:flex-row flex-col lg:justify-between md:justify-between justify-center lg:items-start items-center lg:gap-12 lg:px-32 md:px-10 px-8  py-10">
                <div className=" lg:w-1/2 md:w-1/2 flex flex-col justify-start items-start ">
                    <div className="flex flex-col justify-start items-start gap-2 py-4">
                        <h1 className=" text-xl font-primary font-bold ">اترك لنا طلبك </h1>
                        <p className=" text-md font-medium font-primary ">يمكنك ان ترسل طلبك او اي تساؤل عبر تعبئة النموذج التالي وسوف نرد عليك في اسرع وقت ممكن</p>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2 pb-4 ">
                        <h1 className=" text-xl font-primary font-bold ">يمكنك ايضاً</h1>
                        <p className=" text-md font-medium font-primary ">ان تزورنا في موقع المصنع او المعرض  في شارع الخمسين بعد جولة بيت بوس </p>
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d123126.28504538229!2d44.1109916!3d15.338725199999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1603ddedd5057059%3A0xc1580f585cd0d325!2z2YXYtdmG2Lkg2LHZiNin2LPZiiDYp9mE2K3Yr9mK2Ksg2YTZhNis2LHYp9mG2YrYqiDZiNin2YTYsdiu2KfZhQ!5e0!3m2!1sar!2s!4v1721862338585!5m2!1sar!2s"  height="300" className="w-full"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="lg:w-1/2 md:w-1/2 w-full ">
                    <form onSubmit={handleSubmit}>
                        <div className=" flex flex-col items-start justify-center gap-2 my-4">
                            <label className=" font-primary text-md ">الاسم الكامل <span className=" text-red-700">*</span></label>
                            <input className=" w-full border p-2 outline-none "
                              value={formData.name}
                              name="name"
                              onChange={handleInputChange}
                              />
                               {errors.name && <span className="text-red-400 font-primary">{errors.name}</span>}
                        </div>
                        <div className=" flex flex-col items-start justify-center gap-2 my-4">
                            <label className=" font-primary text-md ">البريد الإلكتروني </label>
                            <input className=" w-full border p-2 outline-none " 
                              value={formData.email}
                              name="email"
                              onChange={handleInputChange}/>
                               {errors.email && <span className="text-red-400 font-primary">{errors.email}</span>}
                        </div>
                        <div className=" flex flex-col items-start justify-center gap-2 my-4">
                            <label className=" font-primary text-md ">نص الرسالة <span className=" text-red-700">*</span></label>
                            <textarea className=" w-full border p-2 outline-none h-40 " 
                              value={formData.messageText}
                              name="messageText"
                              onChange={handleInputChange}
                              ></textarea>
                               {errors.messageText && <span className="text-red-400 font-primary">{errors.messageText}</span>}
                        </div>
                        <button className="font-primary font-bold  py-2 px-5 rounded-sm bg-primary" >ارسل رسالة </button>
                    </form>
                </div>
            </div>
            </div>
            
        <Footer/>
        <ToastContainer />
    </div>
  );
}

export default Products;
