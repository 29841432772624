import React, { useState } from "react";
const CreateWork =({   addWork, open ,formData, onUpdate })=>{
  
  const [errors, setErrors] = useState({});
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      const updatedData = { ...formData, [name]: value };
      
      if(event.target.files === null){
       
        updatedData.image = ''
      }else{
        updatedData.image = event.target.files[0]
      }
      console.log(updatedData)
      onUpdate(updatedData);
    };
  
    const handleSubmit = (event) => {
      const newErrors = {};
      if(!formData.image){
        newErrors.image = 'يجب ادخال صورة للعمل'
      }
      setErrors(newErrors);
      event.preventDefault();
      if (Object.keys(newErrors).length === 0) {
        const formData2 = new FormData();
        formData2.append('image',formData.image );
       console.log( formData2.get('image'))
        if(formData._id){
          formData2.append('_id',formData._id);
        } 
        console.log(formData2)
      
    
          console.log("submit")
          addWork(formData2);
        
        onUpdate({ image:''})
      }
     
    };
    return (
        
        <>
            <div className={`absolute left-0 z-10  h-screen transition-all bg-white ${ open ? ' transform:translate-x-450  transition-all' : ' transform:translate-x-0 transition-all p-0 m-0 ' } `}  >
               <h2 className={`font-secondary text-center p-4 text-slate-700  ${open ? '' : 'hidden'}`}>اضافة عمل</h2>
               <hr/>
            <form onSubmit={handleSubmit}  class={`max-w-md mx-auto mt-8 ${open ? 'show' : 'hidden'} `}>
           
            <div className="mx-4 my-2">
                <label for="small-input" class="block  text-sm font-primary mb-1 text-gray-900 dark:text-white">اختر صورة العمل </label>
                <input type="file"  id="small-input" name="image" onChange={handleInputChange} class="block w-full p-2 text-gray-700 border text-[14px] font-bold border-gray-300 rounded-md bg-gray-50 outline-none focus:shadow-md shadow-purple  focus:ring-primary focus:border-primary  dark:border-primary " />
                {errors.image && <span className="text-red-400 font-primary">{errors.image}</span>}
            </div>
            <div className="mx-4 my-2">
            <button type="submit" class=" block w-full text-white bg-primary p-2 border rounded-md hover:opacity-80 font-bold">تم</button>
            </div>
            <div className="mx-4 my-2">
            <button type="reset" class=" block w-full text-slate-700 bg-gray-100 p-2 border rounded-md hover:opacity-80 font-bold transition-all hover:bg-gray-600  hover:text-gray-50">إلغاء</button>
            </div>
            </form>
              </div>
        </>
    )
}

export default CreateWork