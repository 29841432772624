
import React from "react";

import Header from "../Navbar";
import Head from '../TopSection'
import Gallery from "../Gallary";
import Footer from "../footer";

function Home() {
  return (
    <div>
         <Head/>
        <Header />
        <h1 className="py-20 font-primary font-bold lg:text-4xl text-2xl text-center ">الأعمال </h1>
        <Gallery count={0}/>
        <Footer/>
    </div>
  );
}

export default Home;
