import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
const Top = ()=>{

return(

    <div className="flex flex-row justify-between items-center w-fill p-2  bg-black max-lg:hidden">
      
    <div className="flex justify-center items-center gap-4 ">
    <div className="flex justify-center items-center gap-1 mr-20">
    <FaLocationDot className=" font-secondary text-secondary"/>
      <p className="font-primary text-gray-50 "> صنعاء - الصباحه - امام مصنع السنباني للأدوية </p>
    </div>
    <div className="flex justify-center items-center gap-1">
    <FaPhoneVolume className="font-secondary text-secondary" />
    <span className="font-primary  text-gray-50">9671526006+</span>
    
    </div>
   <div className="flex gap-1 justify-center items-center">
   <MdEmail className="font-secondary text-secondary" />
   <a href="o" className=" font-primary  text-gray-50">
       info@rawasy-modern.com
    </a>
   
   </div>
    </div>
    <div className="flex justify-center items-center ml-20 gap-4 text-lg">
    <a href="https://www.facebook.com/profile.php?id=61553956433601&mibextid=ZbWKwL"><FaFacebookSquare  className="text-secondary" /></a>
    <FaInstagramSquare className="text-secondary"/>
    <FaSquareXTwitter className="text-secondary" />
    </div>
  </div> 
)

}

export default Top;