// src/components/Gallery.js
import React, { useState ,useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Gallery = (count) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setWorks] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    AOS.init({ duration: 1000 });
    fetchWorks()
  }, []);
  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };
  const fetchWorks = async () =>{
    try {
      const response = await fetch(`${apiUrl}/api/Works/`,{
      });
      const data = await response.json();
      console.log(data)

      if(data.length > 5){
        
        if(count.count === 0){
         setWorks(data);
        }else if (count.count === 1){
         const firstSixItems = data.slice(0, 6);
         setWorks(firstSixItems);
        }
       }else{
         setWorks(data);
       }
  
      console.log(data)
    } catch (error) {
      console.error('Error fetching Work:', error);
    }
  };
  const closeModal = () => {
    setSelectedImage(null);
    setIsOpen(false);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div  className="grid grid-cols-1 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {images.map((work, index) => (
          <div key={index} data-aos="zoom-in"  className=" overflow-hidden rounded-lg shadow-lg cursor-pointer" onClick={() => openModal(work.image)}>
            <img  src={`${apiUrl}/api/uploads//${work.image}`}  alt={`Gallery ${index}`} className="w-full h-[200px] object-cover"/>
          </div>
        ))}
      </div>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="relative">
            <button onClick={closeModal} className="absolute top-0 right-0 mt-4 mr-4 text-white text-3xl">&times;</button>
            <img  src={`${apiUrl}/api/uploads//${selectedImage}`} alt="Selected" className="max-w-full max-h-screen rounded-lg"/>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
