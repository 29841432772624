
import React from "react";
import Gallery from "./Gallary";


function Works() {
  return (
    <div className=" w-full mx-auto py-10 flex flex-col gap-y-10 justify-center items-center bg-gray-50" id="works">
      <h2 className="text-center  font-primary font-bold lg:text-5xl text-2xl  mb-10 ">الأعمال </h2>
        <Gallery count={1}/>
        <a href="/works" className="font-primary font-medium  py-2 px-6 rounded-sm bg-white border-2 border-primary transition-colors duration-700 ease-in-out hover:bg-primary ">انظر المزيد </a>
    </div>
  );
}

export default Works;
