import React, { useState , useEffect } from "react";
import SideBar from "../SideBar";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const cookies = new Cookies();

// get token generated on login
const token = cookies.get("TOKEN");
const apiUrl = process.env.REACT_APP_API_URL;
const Message = ()=>{
  const [messages, setMessages] = useState([]);
 
  useEffect(() => {
      fetchMessages();
      // fetchImage();
    }, []);
  const fetchMessages = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/messages`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setMessages(data);
        console.log(data)
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };


    const deleteMessage = async (messageId) => {
      try {
        await fetch(`${apiUrl}/api/messages/${messageId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedServices = messages.filter((service) => service.id !== messageId);
       setMessages(updatedServices)
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    };
    const handleDelete = (id) => {
      const confirmDelete = () => {
        // Perform the delete action here, e.g., making an API call
       
        deleteMessage(id)
      };
    
      const cancelDelete = () => {
        toast.info('تم التراجع عن الحذف ');
      };
    
      toast(
        ({ closeToast }) => (
          <div>
             <p> هل انت متأكد من حذف الرسالة ؟</p>
            <button
              className=" bg-green-500 px-4 text-white font-primary rounded-sm m-2"
              onClick={() => {
                confirmDelete();
                closeToast();
              }}
            >
              نعم
            </button>
            <button
            className=" bg-red-500 px-4 text-white font-primary rounded-sm "
              onClick={() => {
                cancelDelete();
                closeToast();
              }}
            >
             لا
            </button>
          </div>
        ),
        {
          closeOnClick: false,
          autoClose: false,
        }
      );
    };
    if(token){
      return(
        <>
    <div className=" lg:flex relative w-full bg-gray-100 ">
        <SideBar/>
        <div className=" lg:w-full overflow-x-auto flex flex-col justify-start gap-4 m-14" >    
    <table class="lg:w-full min-w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    الاسم
                      </th>
                <th scope="col" class="px-6 py-3">
                    الرسالة
                </th>
                <th scope="col" class="px-6 py-3">
                    الايميل
                </th>
                <th scope="col" class="px-6 py-3">
                    تاريخ الرسالة 
                </th>     
                <th scope="col" class="px-6 py-3">
                    ادارة
                </th>
            </tr>
        </thead>
        <tbody>
         {
          messages.map((message , index)=>{
            const {id,name , messageText  ,email, createdAt } = message
            return (
          <>
              <tr key={index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                 {name}
              </th>
              <td class="px-6 py-4">
                 {messageText}
              </td>
              <td class="px-6 py-4">
                 {email}
              </td>
              <td class="px-6 py-4">
                 {createdAt}
              </td>
              <td class="px-6 py-4">
                  <a href="#" class="font-medium text-red-600 dark:text-red-500 hover:underline" onClick={()=>{
                                handleDelete(id)
                            }} >حذف</a>
              </td>
          </tr>
          </>
            )
          })
         }
        </tbody> 
    </table>
</div>
<ToastContainer />
</div>

        </>
    )
    } else {
      // returns the user to the landing page if there is no valid token set
      return (
        <>
         NO AUTHORIZE 
        </>
      );
    }
 
}
export default Message