import React, { useState , useEffect } from "react";
import SideBar from "../SideBar";
import { IoIosAdd } from "react-icons/io";
import CreateWork from "./CreateWork";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
const cookies = new Cookies();

// get token generated on login
const token = cookies.get("TOKEN");

const Work = ()=>{
  const apiUrl = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({});

  const handleFormUpdate = (updatedData) => {
    setFormData(updatedData);
  };
  const [Works, setWorks] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsOpen(false);
  };
  useEffect(() => {
    AOS.init({ duration: 1000 });
      fetchWorks();
      // fetchImage();
    }, []);
  const fetchWorks = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/Works/`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setWorks(data);
        console.log(data)
      } catch (error) {
        console.error('Error fetching Work:', error);
      }
    };

    const deleteWork = async (WorkId) => {
      try {
        await fetch(`${apiUrl}/api/Works/${WorkId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('تم الحذف بنجاح');
        const updatedWorks = Works.filter((Work) => Work.id !== WorkId);
       setWorks(updatedWorks)
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    };
const addWork = async (WorkData) => {
console.log("Work")
  try {
          const response = await fetch(`${apiUrl}/api/Works/`, {
            method: 'POST',
           body : WorkData,
           headers: {
            Authorization: `Bearer ${token}`,
          },
          });
          console.log("sub2")
          const data = await response.json();
          toast.success('تم الحفظ بنجاح');
        fetchWorks()
          setOpen(false);
      
  } catch (error) {
    console.error('Error adding user:', error);
  }
};
const handleDelete = (id) => {
  const confirmDelete = () => {
    // Perform the delete action here, e.g., making an API call
   
    deleteWork(id)
  };

  const cancelDelete = () => {
    toast.info('تم التراجع عن الحذف ');
  };

  toast(
    ({ closeToast }) => (
      <div>
         <p> هل انت متأكد من حذف الصورة؟</p>
        <button
          className=" bg-green-500 px-4 text-white font-primary rounded-sm m-2"
          onClick={() => {
            confirmDelete();
            closeToast();
          }}
        >
          نعم
        </button>
        <button
        className=" bg-red-500 px-4 text-white font-primary rounded-sm "
          onClick={() => {
            cancelDelete();
            closeToast();
          }}
        >
         لا
        </button>
      </div>
    ),
    {
      closeOnClick: false,
      autoClose: false,
    }
  );
};
const [open , setOpen] = useState(false)
if(token){
  return(
    <>
<div className=" lg:flex relative  w-full bg-gray-100 ">
    <SideBar/>
   <CreateWork open = {open} addWork={addWork}  formData={formData} onUpdate={handleFormUpdate} />
    <div className="w-full flex flex-col justify-start gap-4 lg:m-14 p-2" >    
    <button onClick={()=>{ setOpen(!open)}} className="px-4 py-2 border-2 flex justify-center items-center border-primary hover:bg-primary transition-all hover:text-white w-[120px] ">
       <h5 className="font-primary text-[15px]" >اضافة</h5>
       <IoIosAdd/>
    </button>
    <div className="container mx-auto px-4 py-8">
      <div  className="grid grid-cols-1 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {Works.map((work , index)=>{
  const {id , image} = work
            return (
            <>
              <div className=" flex flex-col justify-center items-center">
              <div key={index} data-aos="zoom-in"  className=" z-0 overflow-hidden rounded-lg shadow-lg cursor-pointer" onClick={() => openModal(image)}>
                <img src={`${apiUrl}/api/uploads//${image}`} alt={`Gallery ${index}`} className="w-full h-[200px] object-cover"/>
                
              </div>
              <button onClick={()=>{handleDelete(id)}} className="font-primary font-bold  py-1 px-2 m-2 rounded-sm bg-red-500 text-gray-50">حذف</button>
              </div>
              </>
            )
        })}
      </div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="relative">
            <button onClick={closeModal} className="absolute top-0 right-0 mt-4 mr-4 text-white text-3xl">&times;</button>
            <img  src={`${apiUrl}/api/uploads//${selectedImage}`} alt="Selected" className="max-w-full max-h-screen rounded-lg"/>
          </div>
        </div>
      )}
    </div>
</div>
<ToastContainer />
</div>

    </>
)
} else {
  return (
    <>
     NO AUTHORIZE 
    </>
  );
}
 
}

export default Work