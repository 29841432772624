import React from "react";
import Navbar from "./Navbar";
import ImageSlider from './Slider/slider';
import Top from "./TopSection";
const Header = () => {
  return (
    <>
    <div className="w-full h-screen bg-gray-50" id="home"
          >
  <header className="w-full h-full">
     <Top/>
     <Navbar/>
     <ImageSlider/>
      </header>
    </div>
    </>
  );
};
export default Header;