import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Dashboard from './components/Admin/Dashboard';
import Product from './components/Admin/Products/ViewProduct';
import Work from './components/Admin/Works/ViewWork';
import Message from './components/Admin/messages/viewMessage';
import Login from './components/Admin/login/login';
import User from './components/Admin/user/Viewuser';
import Products from './components/pages/products'
import Works from './components/pages/works'
import AboutUs from './components/pages/AboutUs'
import ContactUs from './components/pages/contactUs'
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" >
    <Route path="admin">
      <Route path='Dashboard'  element={<Dashboard/>}/>
      <Route path='login' element={<Login/>}/>
      <Route path='product' element={<Product/>}/>
      <Route path='works' element={<Work/>}/>
      <Route path='message' element={<Message/>}/>
      <Route path='user' element={<User/>}/>
    </Route>
    <Route path="/products" element={<Products />}/>
    <Route path="/works" element={<Works />}/>
    <Route path="/about" element={<AboutUs />} />
    <Route path="/contactUs" element={<ContactUs />} />
    <Route path="" element={<App />} />
      {/* ... etc. */}
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
