

import React from "react";
import {motion} from 'framer-motion';
import { fadeIn } from "../../variants";
import Header from "../TopSection";
import Navbar from '../Navbar'
import Footer from "../footer";
const contents = [{
    name : 'رؤيتنا',
    text :'تتركز رؤية المصنع على التطور الدائم والتقدم في مجال تصنيع الجرانيت والرخام بما يتناسب مع التطور العمراني والاقتصادي بالجمهورية اليمنية ، وتوفير جميع متطلبات السوق اليمني',
    icon : 'images/m1.png'
},
{
    name : 'رسالتنا',
    text:'تقديم منتجات عالية الجودة وخدمات ممتازة بأسعار منافسة ، تحافظ على علاقات طويلة الأمد مع عملائنا ، والإسهام في تنمية ونهضة القطاع الصناعي والاقتصادي في بلدنا ',
    icon:'images/m2.png'
},
{
    name:'قيمنا',
    text :'نلتزم بأعلى معايير النزاهة في كل جوانب أعمالنا ، حيث نستغل التكنولوجيا الحديثة لتحسين عملياتنا وندعم استدامة البيئة كمبدأ أساسي ، ونضع رضا العملاء كأولوية لا تقبل المساومة ',
    icon:'images/m3.png'
},
{
    name:'مهمتنا',
    text :'تتجلى مهمتنا في اظهار جمال الحجر المحلي وصنع منتجات عالية الجودة من الجرانيت والرخام تعيد تعريف المساحات من خلال الاستغلال الامثل لجميع مواردنا مع تبني احدث التقنيات ',
    icon:'images/m4.png'
}
]


function Home() {
  return (
    <div>
        <Header />
        <Navbar/>
        
        <div className="w-fill lg:p-24 md:p-18 p-12 parallax2 h-auto py-20 "  id="about">
        <h2 className=" text-black font-primary text-center font-bold pb-5 lg:text-4xl text-3xl">من نحن</h2>
        <p className=" lg:text-2xl text-xl font-primary text-center pb-20">تعرف أكثر على مصنع رواسي الحديث للجرانيت</p>
       <div className="lg:px-10 md:px-6 px-2 flex lg:flex-row xl:flex-row md:flex-row flex-col lg:justify-center justify-center items-center gap-8 lg:gap-12">
          
            <motion.div
            variants={fadeIn("up" ,0.5 )}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once:true ,amount: 0.7 }}
            
            className="flex flex-col justify-center items-start gap-10">
           
            <h3 className=' text-black font-primary font-bold lg:text-3xl text-lg '>مصنع رواسي الحديث</h3>
               <p className=" font-primary font-medium ">
                لدينا أفضل انواع الجرانيت والرخام مع إمكانية تنفيذ أرقى التصميمات للأرضيات والحوائط ، والمداخل ، والدرج السلالم والأعمدة ، والكتل والنحت.
                <br></br>يقدم مصنع رواسي الحديث للجرانيت والرخام الخدمات التي يحتاجها عملاؤه لأنها تلبي كافة توقعاتهم وتظهر تفوقها.
      
               </p>

             
            </motion.div>
            <motion.img 
               variants={fadeIn("down" ,0.1 )}
               initial="hidden"
               whileInView={"show"}
               viewport={{ once:true ,amount: 0.1 }}
            src="images/factory/im5.JPG" alt="" className="lg:w-[500px]  md:w-[400px] w-[350px]"/>
       </div>
       <div className=" mt-10 grid place-items-center grid-flow-row lg:grid-cols-3 gap-4 xl:grid-cols-4 md:grid-cols-2 grid-cols-1">
       {
                           contents.map((item , ind ,i =0)=>{
                               const {name , text , icon} = item
                               return (
                                   <motion.div
                                    key={ind}
                                    variants={fadeIn("up" ,0.5 )}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{ once:true ,amount: 0.7 }}
                                     className="flex flex-col justify-start gap-y-4 p-4 items-start bg-gray-50  
                                     w-[280px] h-[250px] ">
                                 <div className="flex flex-row justify-center items-center gap-3">
                                 <img src={icon} className=" w-[45px] h-[45px] text-[30px] text-primary font-bold"  alt=""/>
                                 <h2 className="text-[20px] text-black font-bold">{name}</h2>
                                 </div>
                                   <p className="text-center font-primary text-gray-700">
                                        {text}
                                   </p>
                                   </motion.div>
                               )
                           
                           })
                       }
       </div>
       </div>
        <Footer/>
    </div>
  );
}

export default Home;
