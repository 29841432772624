import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { fadeIn } from "../variants";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Products = () => {
  const [products, setProducts] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    AOS.init({ duration: 1000 });
    fetchProducts()
  }, []);
  const fetchProducts = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/products/`,{
      });
      const data = await response.json();
      const updatedServices = data.filter((service) => service.type === 'جرانيت');
      setProducts(updatedServices)
     
      console.log(data)
    } catch (error) {
      console.error('Error fetching service:', error);
    }
  };
  return (
    <section id="product" className="">
      <div className="h-full pb-20">
        <div className="container mx-auto py-10 flex flex-col gap-y-10 justify-center items-center">
          <motion.h1
            variants={fadeIn("up", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="text-center font-primary font-bold lg:text-5xl text-4xl"
          >
            منتجاتنا{" "}
          </motion.h1>
          <motion.p
            variants={fadeIn("up", 0.7)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.9 }}
            className=" text-center text-xl font-primary font-medium px-12"
          >
        يختص مصنع رواسي الحديث بتوفير أفضل أصناف وأنواع الجرانيت والرخام في اليمن
          </motion.p>
          <div className="grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {products.map((product, index) => (
              <div class="max-w-md mx-auto">
                <div data-aos="zoom-in" className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700">
                  <img
                    class="rounded-t-lg w-[250px] h-[150px]"
                    src={`${apiUrl}/api/uploads//${product.image}`}
                    alt=""
                  />

                  <div class="p-5 text-center">
                    <h5 class="text-gray-900 font-medium font-primary  tracking-tight mb-2 dark:text-white">
                      {product.name}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a href="/products" className="font-primary font-medium  py-2 px-6 rounded-sm bg-white border-2 border-primary transition-colors duration-700 ease-in-out hover:bg-primary ">
            انظر المزيد{" "}
          </a>
        </div>
      </div>
    </section>
  );
};

export default Products;
