
import React from "react";
import {motion} from 'framer-motion';
import { fadeIn } from "../variants";
import './style.css'


function About() {
  return (
    <div className="w-fill lg:p-24 md:p-18 p-12 parallax h-auto "  id="about">
       
    <div className="lg:px-10 md:px-6 px-2 flex lg:flex-row xl:flex-row md:flex-row flex-col lg:justify-center justify-center items-center gap-8 lg:gap-12">
       
         <motion.div
         variants={fadeIn("up" ,0.5 )}
         initial="hidden"
         whileInView={"show"}
         viewport={{ once:true ,amount: 0.7 }}
         
         className="flex flex-col justify-center items-start gap-10">
         <h2 className=" text-black font-primary text-2xl">من نحن ؟</h2>
         <h3 className=' text-black font-primary font-bold lg:text-5xl text-2xl '>مصنع رواسي الحديث</h3>
            <p className=" font-primary font-medium text-xl">
                مصنع رواسي الحديث للجرانيت والرخام هو مصنع مختص بصناعة جميع انواع 
                بلاط الجرانيت والرخام بكافة الاشكال والمقاسات، ويعتبر مصنع رواسي 
                من أكبر المصانع العاملة في العاصمة اليمنية صنعاء لتلبية جميع احتياجات 
                السوق اليمني من منتجات الرخام والجرانيت. 
            </p>
            <a href="about" className="font-primary font-bold  py-2 px-5 rounded-sm bg-primary">اعرف عنا أكثر </a>
         </motion.div>
         <motion.img 
            variants={fadeIn("down" ,0.1 )}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once:true ,amount: 0.1 }}
         src="images/factory/Layer.png" alt="" className="lg:w-[500px]  md:w-[400px] w-[350px] lg:h-[400px]"/>
    </div>
    </div>
  );
}

export default About;
