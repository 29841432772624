import React, { useState } from "react";
const CreateProduct = ({
  updateProduct,
  addProduct,
  open,
  formData,
  onUpdate,
}) => {
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedData = { ...formData, [name]: value };

    console.log(updatedData);
    onUpdate(updatedData);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if(event.target.files === null) {
      onUpdate((prevData) => ({ ...prevData, image: '' }));
    } else {
      onUpdate((prevData) => ({ ...prevData, image: file }));
    }
  }
  const handleSubmit = (event) => {
    const newErrors = {};
    if(!formData.name){
      newErrors.name = 'يجب ادخال اسم المنتج'
    }
    if(!formData.type){
      newErrors.type = 'يجب ادخال نوع المنتج'
    }
    if(!formData.id){
      if(!formData.image){
      newErrors.image = 'يجب اختيار الصورة '
     }
    }
  
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const formData2 = new FormData();
    formData2.append("image", formData.image);
    console.log(formData2.get("image"));
    formData2.append("name", formData.name);
    formData2.append("type", formData.type);
    if (formData.id) {
      formData2.append("id", formData.id);
    }
    console.log(formData2);
    event.preventDefault();
    if (formData2.get("id") !== null) {
      updateProduct(formData2);
    } else {
      console.log("submit");
      addProduct(formData2);
    }
    }
    onUpdate({name :''  , image:'' , type:''})
  };

  
  return (
    <>
      <div
        className={`absolute left-0  h-screen transition-all bg-white ${
          open
            ? " transform:translate-x-450  transition-all"
            : " transform:translate-x-0 transition-all p-0 m-0 "
        } `}
      >
        <h2
          className={`font-secondary text-center p-4 text-slate-700  ${
            open ? "" : "hidden"
          }`}
        >
          اضافة منتج{" "}
        </h2>
        <hr />
        <form
          onSubmit={handleSubmit}
          class={`max-w-md mx-auto mt-8 ${open ? "show" : "hidden"} `}
        >
          <div className="mx-4 my-2">
            <label
              for="small-input"
              class={`block  text-sm font-primary mb-1 text-gray-900 dark:text-white`}
            >
              اسم المنتج
            </label>
            <input
              type="text"
              id="small-input"
              value={formData.name}
              name="name"
              onChange={handleInputChange}
              class="block w-full p-2 text-gray-700 border text-[14px] font-bold border-gray-300 rounded-md bg-gray-50 outline-none focus:shadow-md shadow-purple  focus:ring-primary focus:border-primary  dark:border-primary "
            />
              {errors.name && <span className="text-red-400 font-primary">{errors.name}</span>}
          </div>
          <div className="mx-4 my-2">
            <label
              for="small-input"
              class={`block  text-sm font-primary mb-1 text-gray-900 dark:text-white`}
            >
              نوع المنتج
            </label>
            <select
              id="small-input"
              value={formData.type}
              name="type"
              onChange={handleInputChange}
              class="block w-full p-2 text-gray-700 border text-[14px] font-bold border-gray-300 rounded-md bg-gray-50 outline-none focus:shadow-md shadow-purple  focus:ring-primary focus:border-primary  dark:border-primary "
            >
              <option value=""></option>
              <option value="جرانيت">جرانيت</option>
              <option value="رخام">رخام</option>
              </select>
              {errors.type && <span className="text-red-400 font-primary">{errors.type}</span>}
          </div>
      
          <div className="mx-4 my-2">
            <label
              for="small-input"
              class="block  text-sm font-primary mb-1 text-gray-900 dark:text-white"
            >
              صورة المنتج {" "}
            </label>
            <input
              type="file"
              id="small-input"
              name="image"
              onChange={handleFileChange}
              class="block w-full p-2 text-gray-700 border text-[14px] font-bold border-gray-300 rounded-md bg-gray-50 outline-none focus:shadow-md shadow-purple  focus:ring-primary focus:border-primary  dark:border-primary "
            />
             {errors.image && <span className="text-red-400 font-primary">{errors.image}</span>}
          </div>
          <div className="mx-4 my-2">
            <button
              type="submit"
              class=" block w-full text-white bg-primary p-2 border rounded-md hover:opacity-80 font-bold"
            >
              تم
            </button>
          </div>
          <div className="mx-4 my-2">
            <button
              type="reset"
              class=" block w-full text-slate-700 bg-gray-100 p-2 border rounded-md hover:opacity-80 font-bold transition-all hover:bg-gray-600  hover:text-gray-50"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateProduct;
