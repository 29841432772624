import React, { useState } from 'react';
import logo2 from '../img/logo.png'
import { Link } from "react-scroll";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const navLinks = [
    { href: "/", label: "الرئيسية" },
    { href: "about", label: "من نحن" },
    { href: "products", label: "المنتجات" },
    { href: "works", label: "الأعمال" },
   
  ];
  return (
    <nav className="bg-gray-50 w-full md:relative md:z-0 fixed z-50 p-2">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-auto p-2">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden ">
            {/* Mobile menu button */}
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md  text-black  hover:bg-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
        
              {isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              )}
            </button>
          </div>
          <div className="flex-1 flex justify-between lg:items-center md:items-center items-center  sm:items-stretch ">
            <div className="">
            <a href="/" className="text-3xl font-bold">
          <img alt="" src={logo2} className="mr-10" style={{ width:'188px' , height:'39px' }}/>
            </a>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <ul className="flex gap-8">
                
              {navLinks.map((item) => (
                <li   key={item.id}>
                  <a
                  className="text-black font-primary font-bold cursor-pointer leading-normal  group transition duration-300"
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    href={item.href}
                  >
                   
                   <span class='bg-right-bottom bg-gradient-to-r p-2 from-yellow-300 to-yellow-800 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'> 
                    {item.label}
                    </span>
                  </a>
                 
                  </li>
              ))}
         
              
              </ul>
              
            </div>
            <a

className="block px-5 py-2 rounded-sm text-base  max-lg:hidden font-primary font-bold   bg-secondary text-black  cursor-pointer"
  onClick={() => {
    setIsOpen(!isOpen);
  }}
  href="contactUs"
>
  تواصل معنا
  </a>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state */}
      <div className={`sm:hidden transition-all  duration-300 ease-in-out ${isOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`} id="mobile-menu">
        <div className="px-3 pt-1 pb-2 space-y-1">
        <div className="px-3 pt-1 pb-2 space-y-1">       
        {navLinks.map((item) => (

                  <a
                  className="p-2 m-2 border-1 block rounded-full text-black font-primary hover:text-primary duration-300  cursor-pointer"
                  key={item.id}
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    href={item.href}
                  >
                    {item.label}
                  </a>
            
              ))}
                 <a
                  className="p-2 m-2 border-1 block rounded-full text-black font-primary hover:text-primary duration-300  cursor-pointer"
                 
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    href="contactUs"
                  >
                    تواصل معنا
                  </a>
        </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
