
import React from "react";

import Header from "./Header";
import About from "./About";
import Products from "./Products";
import Works from "./Works";
import Footer from "./footer";


function Home() {
  return (
    <div>
        <Header />
        <About />
        <Products/>
        <Works />
        <Footer/>
    </div>
  );
}

export default Home;
