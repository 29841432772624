import React from 'react';

const Filter = ({ types, selectedType, onSelectType }) => {
  return (
    <div >
      <div className='flex flex-row gap-8 '>
        {types.map((type) => (
          <button
            key={type}
            onClick={() => onSelectType(type)}
            className={selectedType === type ? 'active font-primary   py-2 lg:px-12  px-8 rounded-sm bg-primary' : 'font-primary   py-2 lg:px-12 px-8 rounded-sm bg-gray-50'}
          >
            {type}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Filter;