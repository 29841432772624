import React, { useState, useEffect } from "react";
import SideBar from "../SideBar";
import { IoIosAdd } from "react-icons/io";
import Createuser from "./Createuser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "universal-cookie";
const cookies = new Cookies();
const apiUrl = process.env.REACT_APP_API_URL;
// get token generated on login
const token = cookies.get("TOKEN");
const User = () => {
  const [formData, setFormData] = useState({});
  const handleFormUpdate = (updatedData) => {
    setFormData(updatedData);
  };
  const [users, setUser] = useState([]);

  useEffect(() => {
    fetchUsers();
    // fetchImage();
  }, []);
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/users/` ,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUser(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching service:", error);
    }
  };
  const updateUser = async (UserData) => {
    console.log("up")
    try {
      const response = await fetch(
        `${apiUrl}/api/users/${UserData.id}`,
        {
          method: "PUT",
          body: JSON.stringify(UserData),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedProduct = await response.json();
      toast.success('تم التعديل بنجاح ');
      // const updatedProducts = products.map((product) =>
      //   product._id === updatedProduct._id ?  updatedProduct: product
      // );
      // setProducts(updatedProducts);
      fetchUsers();
      setOpen(false);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await fetch(`${apiUrl}/api/users/${userId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedServices = users.filter((user) => user.id !== userId);
      setUser(updatedServices);
      toast.success('تم الحذف بنجاح');
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  const addUser = async (userData) => {
    console.log("service");
    try {
      const response = await fetch(`${apiUrl}/api/users/`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("sub2");
      const data = await response.json();
      toast.success('تم الحفظ بنجاح ');
      fetchUsers();
      setOpen(false);
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };
  const handleDelete = (id) => {
    const confirmDelete = () => {
      // Perform the delete action here, e.g., making an API call
     
      deleteUser(id)
    };
  
    const cancelDelete = () => {
      toast.info('تم التراجع عن الحذف ');
    };
  
    toast(
      ({ closeToast }) => (
        <div>
           <p> هل انت متأكد من حذف المستخدم ؟</p>
          <button
            className=" bg-green-500 px-4 text-white font-primary rounded-sm m-2"
            onClick={() => {
              confirmDelete();
              closeToast();
            }}
          >
            نعم
          </button>
          <button
          className=" bg-red-500 px-4 text-white font-primary rounded-sm "
            onClick={() => {
              cancelDelete();
              closeToast();
            }}
          >
           لا
          </button>
        </div>
      ),
      {
        closeOnClick: false,
        autoClose: false,
      }
    );
  };
  const [open, setOpen] = useState(false);
  if(token){
    return (
      <>
        <div className=" lg:flex relative  w-full bg-gray-100 ">
          <SideBar />
          <Createuser
            open={open}
            addUser={addUser}
            updateUser={updateUser}
            formData={formData}
            onUpdate={handleFormUpdate}
          />
          <div className="w-full overflow-x-auto flex flex-col justify-start gap-4 lg:m-14 p-2">
            <button
              onClick={() => {
                setOpen(!open);
              }}
              className="px-4 py-2 border-2 flex justify-center items-center border-primary rounded-sm hover:bg-primary transition-all hover:text-white w-[120px] "
            >
              <h5 className="font-primary text-[15px]">اضافة</h5>
              <IoIosAdd />
            </button>
            <table class="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    اسم المستخدم
                  </th>
                  <th scope="col" class="px-6 py-3">
                    كلمة السر
                  </th>
                  <th scope="col" class="px-6 py-3">
                    ادارة
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  const { id, username, password } = user;
                  return (
                    <>
                      <tr
                        key={index}
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {username}
                        </th>
                        <td class="px-6 py-4">{password}</td>
                        <td class="px-6 py-4 flex items-center gap-4">
                          <a
                            href="#"
                            class="font-medium text-green-600 dark:text-blue-500 hover:underline"
                            onClick={() => {
                              setFormData(user);
                              setOpen(true);
                            }}
                          >
                           تعديل 
                          </a>
                          <a
                            href="#"
                            class="font-medium text-red-600 dark:text-blue-500 hover:underline"
                            onClick={() => {
                            handleDelete(id);
                            }}
                          >
                            حذف 
                          </a>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <ToastContainer />
          </div>
        </div>
      </>
    );
  } else {
    // returns the user to the landing page if there is no valid token set
    return (
      <>
       NO AUTHORIZE 
      </>
    );
  }

};

export default User;
