// src/components/ProductList.js
import React from 'react';

const ProductList = ({ products }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-3 gap-4">
    {products.map((product, index) => (
      <div class="max-w-md mx-auto">
        <div key={index} data-aos="zoom-in" className="bg-white shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700">
          <img
            class="rounded-t-lg w-[250px]"
            src={`${apiUrl}/api/uploads//${product.image}`}
            alt=""
          />

          <div class="p-5 text-center">
            <h5 class="text-gray-900 font-medium font-primary  tracking-tight mb-2 dark:text-white">
              {product.name}
            </h5>
          </div>
        </div>
      </div>
    ))}
  </div>
  );
};

export default ProductList;
