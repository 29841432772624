import React ,{useEffect} from "react";
import SideBar from "./SideBar";
import { useState } from "react";
import { TbMilk } from "react-icons/tb";
import { VscLayersActive } from "react-icons/vsc";
import { FaUsersCog } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
const Dashboard = ()=>{
const [ open  , srtOpen] = useState(true)
const [ user  , stUser] = useState(true)
const [ product  , stProduct] = useState(true)
const [ work  , stWork] = useState(true)
const [ message  , stMessage] = useState(true)

const apiUrl = process.env.REACT_APP_API_URL;
useEffect(() => {
       GetAll();
    // fetchImage();
  }, []);
const GetAll = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/login/`, {
        method: "GET",
      });
      console.log("sub2");
      const data = await response.json();
     stUser(data.user)
     stProduct(data.product)
     stWork(data.work)
     stMessage(data.message)
     console.log(data)
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };
    return(
        <>    
           <div className=" lg:flex relative  w-full bg-gray-100 ">
           <SideBar open = {open}/>
                        <div className="grid lg:grid-cols-2 grid-cols-1 m-8  gap-4  justify-center items-center">
                            <div className=" bg-white flex flex-col justify-center items-center shadow-lg shadow-purple gap-y-3 px-40 py-5  rounded-md ">
                                <div className="p-2 bg-purple-300 rounded-full">
                                <TbMilk className="text-[25px]  text-purple-900 "/>
                                </div>
                                <h2 className="text-[15px] text-gray-600 font-primary">المنتجات</h2>
                                <h4 className="text-[25px] text-gray-700 font-bold">{product}</h4>
                            </div>
                            <div className="  bg-white flex flex-col justify-center items-center shadow-lg shadow-purple gap-y-3 px-40 py-5  rounded-md ">
                                <div className="p-2 bg-orange-300 rounded-full">
                                <VscLayersActive className="text-[25px]  text-orange-800 "/>
                                </div>
                                <h2 className="text-[15px] text-gray-600 font-primary">الأعمال</h2>
                                <h4 className="text-[25px] text-gray-700 font-bold">{work}</h4>
                            </div>
                     
                            <div className="  bg-white flex flex-col justify-center items-center shadow-lg shadow-purple gap-y-3 px-40 py-5  rounded-md ">
                                <div className="p-2 bg-blue-300 rounded-full">
                                <MdMessage className="text-[25px]  text-blue-900 "/>
                                </div>
                                <h2 className="text-[15px] text-gray-600 font-primary">الرسائل</h2>
                                <h4 className="text-[25px] text-gray-700 font-bold">{message}</h4>
                            </div>
                            <div className="  bg-white flex flex-col justify-center items-center shadow-lg shadow-purple gap-y-3 px-40 py-5  rounded-md ">
                                <div className="p-2 bg-yellow-300 rounded-full">
                                <FaUsersCog className="text-[25px]  text-yellow-900 "/>
                                </div>
                                <h2 className="text-[15px] text-gray-600 font-primary">المستخدمين</h2>
                                <h4 className="text-[25px] text-gray-700 font-bold">{user}</h4>
                            </div>
                        </div>
                    </div>
        </>
    )
}
export default Dashboard