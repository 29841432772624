
import React ,{useEffect ,useState} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Header from "../TopSection";
import Navbar from '../Navbar'
import Footer from "../footer";
import Filter from "./Filter";
import ProductList from "./ProductList";
import '../style.css'
function Products() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
        fetchProducts()
      }, []);
      const [products, setProducts] = useState([]);
      const apiUrl = process.env.REACT_APP_API_URL;
      const fetchProducts = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/products/`,{

          });
          const data = await response.json();
          setProducts(data);
          console.log(data)
        } catch (error) {
          console.error('Error fetching service:', error);
        }
      };
      const [selectedType, setSelectedType] = useState('');

      const handleSelectType = (type) => {
        setSelectedType(type);
      };
    
      const filteredProducts = selectedType
        ? products.filter(product => product.type === selectedType)
        : products;
    
      const productTypes = [...new Set(products.map(product => product.type))];
    
    
  return (
    <div>
        <Header />
        <Navbar/>
        <div className=" py-20  parallax2">
            <div className="flex flex-col gap-8 justify-center items-center ">
                <h1 className=" font-primary font-bold lg:text-4xl text-3xl text-center  ">منتجاتنا </h1>
                <p className=" font-primary lg:text-xl text-sm p-6 text-center ">نقدم لكم أفضل أنواع وأصناف الرخام والجرانيت بأفضل الاسعار</p>
                <Filter types={productTypes} selectedType={selectedType} onSelectType={handleSelectType} />
                <ProductList products={filteredProducts} />
          </div>
        </div>
        <Footer/>
      
    </div>
  );
}

export default Products;
