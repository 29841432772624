import React from "react";  
import logo from '../../img/logo2.png'
import { RiMenu3Line } from "react-icons/ri";
import { TbMilk } from "react-icons/tb";
import { VscLayersActive } from "react-icons/vsc";
import { FaUsersCog } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { useState } from "react";
import { LuLogOut } from "react-icons/lu";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const SideBar = props =>{
    const [ open  , srtOpen] = useState(true)
    const logout = () => {
        // destroy the cookie
        cookies.remove("TOKEN", { path: "/" });
        // redirect user to the landing page
        window.location.href = "/admin/login";
      }
    return(
        <div className="flex ">
            <div className={` ${open ? 'w-[300px]' : ' lg:w-[70px] w-[0px]'} lg:relative absolute z-10  transition-all bg-slate-700 h-screen`}>
                <nav className="mt-8 text-white font-primary">
               <IoClose className=" text-white text-[20px] mx-4 mb-2 lg:hidden" onClick={()=>{
                srtOpen(!open)
               }}/>
                    {
                        open  ? <div className="flex flex-col justify-center items-center">
                        <img src={logo} 
                        alt=""            
                        style={{ width:"130px" , height:"80px" }}/>
                 </div> : null
                    }
                    <ul className="flex-col  justify-start items-center mx-2 p-2 mt-10 ">
                        <li className=" p-2 my-4 hover:bg-slate-800 transition-all "><a href="/admin/product" className="flex">
                        <TbMilk className="text-[25px]  text-primary "/>
                           {
                            open ?  <h3 className="mx-2 font-primary">المنتجات</h3> : null
                           }
                       </a></li>
                       <li className=" p-2 my-4 hover:bg-slate-800 transition-all "><a href="/admin/works" className="flex">
                        <VscLayersActive className="text-[25px]  text-primary "/>
                           {
                            open ?  <h3 className="mx-2 font-primary">اعمالنا</h3> : null
                           }
                       </a></li>

                       <li className=" p-2 my-4 hover:bg-slate-800 transition-all "><a href="/admin/message" className="flex">
                        <MdMessage className="text-[25px]  text-primary "/>
                           {
                            open ?  <h3 className="mx-2 font-primary">الرسائل</h3> : null
                           }
                       </a></li>
                       <li className=" p-2 my-4 hover:bg-slate-800 transition-all "><a href="/admin/user" className="flex">
                        <FaUsersCog className="text-[25px]  text-primary "/>
                           {
                            open ?  <h3 className="mx-2 font-primary">المستخدمين</h3> : null
                           }
                       </a></li>
                       <li onClick={()=>{logout()}} className=" p-2 my-4 hover:bg-slate-800 transition-all ">
                       <a href="#" className="flex">
                        <LuLogOut className="text-[25px]  text-primary "/>
                           {
                            open ?  <h3 className="mx-2 font-primary">تسجيل خروج</h3> : null
                           }
                           </a>
                       </li>
                    </ul>
                </nav>
            </div>
            <RiMenu3Line className="font-bold text-[25px] cursor-pointer mt-4" onClick={()=>{
                    srtOpen(!open)
                }}/>
        </div>
    )
}
export default SideBar