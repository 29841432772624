import React, { useState, useEffect } from 'react';
import './slider.css';
import {motion} from 'framer-motion'
const images = [
  'images/factory/1.jpeg',
  'images/factory/im1.jpg',
  'images/factory/im6.JPG'
];
const Data = {
  title : 'مصنع رواسي الحديث ',
  description : 'نحن متخصصون في تصنيع منتجات عالية الجودة من الجرانيت والرخام بأسعار تنافسية. نسعى للمساهمة في تنمية القطاعين الصناعي والاقتصادي، مع التركيز على تلبية جميع احتياجات زبائننا بأعلى مستويات الدقة والاحترافية.',
  ButtonText : 'اعرف أكثر',
  URLButton : '',
  isRight : false
 };

function ImageSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [scale, setScale] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
    
      setTimeout(() => {
        setCurrentIndex((currentIndex + 1) % images.length);
        setScale(false);
        setFade(true);
        setTimeout(() => {
          setScale(true);
        }, 100); // Time after which scaling starts after fade-in
      }, 500); // Fade out duration
    }, 9000); // Slide change interval

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="slider w-full relative  h-[100%]  md:h-[90%] md:pt-0 pt-[10%] ">
      <div className={`fade ${fade ? 'in' : 'out'}`}>
        <img src={images[currentIndex]} alt="slide"  className={scale ? 'scale': '' }/>
       <div className='overlay absolute inset-0 bg-primary bg-opacity-50'>

       </div>
        <div className="overlay absolute inset-0 bg-black bg-opacity-80 ">
        <div className='absolute lg:left-[30%] lg:right-[30%] md:left-[25%] md:right-[25%] right-[15%] left-[15%]  top-40 flex flex-col gap-8 justify-center items-center'>
       <h3 className=' text-gray-50 font-primary font-bold lg:text-5xl text-2xl '>{Data.title}</h3>
       <p className=' text-gray-50 font-primary lg:text-xl md:text-lg text-md text-center'>{Data.description}</p>
       <div className=' flex lg:flex-row md:flex-row flex-col lg:gap-10 md:gap-6 gap-4'>
       <div class="wrapper fromRight ">
  <a href="/about" className="font-primary font-bold  py-2 px-5 rounded-sm bg-primary">
    <span>{Data.ButtonText}</span>
    </a>
</div>
       <div class="wrapper">
  <a href="/contactUs" className="font-primary font-bold  py-2 px-5 rounded-sm bg-gray-50">
    <span>تواصل معنا </span>
    </a>
</div>
       </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;
