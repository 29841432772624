import { React, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "universal-cookie";
const cookies = new Cookies();
const Login = ()=>{
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const apiUrl = process.env.REACT_APP_API_URL;
    const login = async (loginData) => {
      try {
        const response = await fetch(`${apiUrl}/api/login/`, {
          method: "POST",
          body: JSON.stringify(loginData),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log("sub2");
        const data = await response.json();
        console.log(response.status)
       if(response.status === 200){
        window.location.href = "/admin/Dashboard";
        cookies.set("TOKEN", data.token, {
          path: "/",
        });
        toast.success('تم الارسال بنجاح');
        setFormData({username:'' , password:''})
       }else{
        toast.error('اسم المستخدم او كلمة السر خاطئة ');
       }
   
       
        console.log(data)
      } catch (error) {
        console.error("Error adding user:", error);
      }
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      const updateData = { ...formData, [name]: value };
      setFormData(updateData)
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const newErrors = {};
     
      if (!formData.username) {
        newErrors.username = 'يجب ادخال اسم المستخدم';
      }
      if (!formData.password) {
        newErrors.password = 'يجب ادخال كلمة السر';
      }
  
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
       login(formData);
      }
    }
    
    return(
        
<section class="h-screen">
  <div class="h-full">
    <div
      class="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
      <div
        class="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
        <img
          src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
          class="w-full"
          alt="Sample image" />
      </div>

      <div class="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12 ">
      <form class=" text-gray-50" onSubmit={handleSubmit}>
            <div className="flex gap-4">             
            <div class="mb-3 lg:w-[50%] w-full flex items-center gap-4 justify-start">
           
              <label
                for="email"
                className="block mb-2 text-sm font-medium text-gray-900"
            
              >
                الاسم
              </label>
              <input
                   value={formData.name}
                   name="username"
                   onChange={handleInputChange}
                className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             
              />
              </div>
              {errors.username && <span className="text-red-400">{errors.username}</span>}
            </div>
            <div className="flex gap-4">             
            <div class="mb-3 lg:w-[50%] w-full flex items-center gap-4 justify-start ">
           
              <label
                for="email"
               className="block mb-2 text-sm font-medium text-gray-900"
            
              >
                كلمة السر 
              </label>
              <input
                   value={formData.password}
                   name="password"
                   onChange={handleInputChange}
                className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             type="password"
             
              />
              </div>
              {errors.password && <span className="text-red-400">{errors.password}</span>}
            </div>

            <div className="m-3 lg:w-[50%] w-full">
              <button
                className="font-primary cursor-pointer leading-normal text-[15px]
       px-4 py-2 rounded-md text-gray-900 border-2 border-cyan-800 xt-gray-50  group transition duration-300"
              >
                ارسال
              </button>
            </div>
          </form>
      </div>
    </div>
  </div>
  <ToastContainer />
</section>

    )
}

export default Login