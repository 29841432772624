import React, { useState , useEffect } from "react";
import SideBar from "../SideBar";
import { IoIosAdd } from "react-icons/io";
import CreateProject from "./CreateProduct";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const cookies = new Cookies();

// get token generated on login
const token = cookies.get("TOKEN");
const apiUrl = process.env.REACT_APP_API_URL;
const Products = ()=>{
  const [formData, setFormData] = useState({name:'',image:'',type:''});
  const handleFormUpdate = (updatedData) => {
    setFormData(updatedData);
  };
  const [products, setProducts] = useState([]);
  
  useEffect(() => {
      fetchProducts();
      // fetchImage();
    }, []);
  const fetchProducts = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/products/`,{
        });
        const data = await response.json();
        setProducts(data);
        console.log(data)
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };
    const updateProduct = async (ProductData) =>{
      try {
          console.log("done")
        const response = await fetch(`${apiUrl}/api/products/${ProductData.get('id')}`, {
          method: 'PUT',
          body: ProductData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedProduct = await response.json();
        toast.success('تم التعديل بنجاح ');
        // const updatedProducts = products.map((product) =>
        //   product._id === updatedProduct._id ?  updatedProduct: product
        // );
        // setProducts(updatedProducts);
       fetchProducts();
        setOpen(false);
      } catch (error) {
        console.error('Error updating user:', error);
      }
    };


    const deleteProduct = async (projectId) => {
      try {
        await fetch(`${apiUrl}/api/products/${projectId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('تم الحذف بنجاح');
        const updatedServices = products.filter((service) => service.id !== projectId);
       setProducts(updatedServices)
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    };
const addProduct = async (productData) => {

  try {
          const response = await fetch(`${apiUrl}/api/products/`, {
            method: 'POST',
           body : productData,
           headers: {
            Authorization: `Bearer ${token}`,
          }
          });
          console.log("sub2")
          const data = await response.json();
          toast.success('تم الحفظ بنجاح ');
        fetchProducts()
          setOpen(false);
  } catch (error) {
    console.error('Error adding user:', error);
  }
};
const handleDelete = (id) => {
  const confirmDelete = () => {
    // Perform the delete action here, e.g., making an API call
   
    deleteProduct(id)
  };

  const cancelDelete = () => {
    toast.info('تم التراجع عن الحذف ');
  };

  toast(
    ({ closeToast }) => (
      <div>
         <p> هل انت متأكد من حذف المنتج ؟</p>
        <button
          className=" bg-green-500 px-4 text-white font-primary rounded-sm m-2"
          onClick={() => {
            confirmDelete();
            closeToast();
          }}
        >
          نعم
        </button>
        <button
        className=" bg-red-500 px-4 text-white font-primary rounded-sm "
          onClick={() => {
            cancelDelete();
            closeToast();
          }}
        >
         لا
        </button>
      </div>
    ),
    {
      closeOnClick: false,
      autoClose: false,
    }
  );
};
const [open , setOpen] = useState(false)
if(token){
  return(
    <>
<div className=" lg:flex relative  w-full bg-gray-100 ">
    <SideBar/>
   <CreateProject open = {open} addProduct={addProduct} updateProduct={updateProduct} formData={formData} onUpdate={handleFormUpdate} />
   <div className="w-full overflow-x-auto flex flex-col justify-start gap-4 lg:m-14 p-2">   
    <button onClick={()=>{ setOpen(!open)}} className="px-4 py-2 border-2 flex justify-center items-center border-primary rounded-sm hover:bg-primary transition-all hover:text-white w-[120px] ">
       <h5 className="font-primary text-[15px]" >اضافة</h5>
       <IoIosAdd/>
    </button>
<table class="lg:w-full min-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
            <th scope="col" class="px-6 py-3">
                اسم المنتج
                  </th>
                  <th scope="col" class="px-6 py-3">
                نوع المنتج
                  </th>
            <th scope="col" class="px-6 py-3 ">
                الصورة
            </th>     
            <th scope="col" class="px-6 py-3">
                ادارة
            </th>
        </tr>
    </thead>
    <tbody>
     {
      products.map((product , index)=>{
        const {id,name  , image ,type } = product
        return (
      <>
          <tr key={index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
             {name}
          </th>
          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
             {type}
          </th>
          <td class="px-6 py-4">
           <img alt="" src={`${apiUrl}/api/uploads//${image}`} style={{ width:'80px' ,height:'80px' }} />
          </td>
          <td class="px-6 py-4 flex  items-center gap-4">
              <a href="#" class="font-medium text-green-600 dark:text-green-500 hover:underline " onClick={()=>{
                 setFormData(product)
                 setOpen(true)
                }} >تعديل</a>

              <a href="#" class="font-medium text-red-600 dark:text-red-500 hover:underline" onClick={()=>{
                            handleDelete(id)
                        }} >حذف</a>
          </td>
      </tr>
      </>
        )
      })
     }
    </tbody> 
</table>
</div>
<ToastContainer />
</div>

    </>
)

} else {
  // returns the user to the landing page if there is no valid token set
  return (
    <>
     NO AUTHORIZE 
    </>
  );
}
}
export default Products