import React ,{useState} from "react";
const CreateService =({  updateUser , addUser, open ,formData, onUpdate })=>{
  
  const [errors, setErrors] = useState({});
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      const updatedData = { ...formData, [name]: value };
      
      console.log(updatedData)
      onUpdate(updatedData);
    };
    const handleSubmit = (event) =>{

    const newErrors = {};
    if(!formData.username){
      newErrors.username = 'يجب ادخال اسم المستخدم'
    }
    if(!formData.password ){
      newErrors.password = 'يجب ادخال كلمة السر'
    }else{
      if(formData.password.length < 5 ){
        newErrors.password2 = 'يجب ان تكون كلمة السر اكثر من 5 حروف وارقام'
      }
       
    if(!formData.password ){
      newErrors.password3 = 'يجب ادخال كلمة السر'
    }
    if (!/[a-z]/.test(formData.password)) {
       newErrors.password4 =' يجب ان يتم ادخال حروف وارقام انجليزية'
    }
    if (!/[0-9]/.test(formData.password)) {
      newErrors.password5 =' يجب ان يتم ادخال حروف وارقام انجليزية'
   }
    }
    event.preventDefault();
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0){
    
      if (formData.id != null ){
        console.log("submit2")
        updateUser(formData);
      } else {
        console.log("submit")
        addUser(formData);
      }
    }
    };
    return (       
        <>
            <div className={`absolute left-0  h-screen transition-all bg-white ${ open ? ' transform:translate-x-450  transition-all' : ' transform:translate-x-0 transition-all p-0 m-0 ' } `}  >
               <h2 className={`font-secondary text-center p-4 text-slate-700  ${open ? '' : 'hidden'}`}>اضافة مستخدم</h2>
               <hr/>
            <form onSubmit={handleSubmit}  class={`max-w-md mx-auto mt-8 ${open ? 'show' : 'hidden'} `}>
            <div className="mx-4 my-2">
                <label for="small-input" class={`block  text-sm font-primary mb-1 text-gray-900 dark:text-white`}>اسم المستخدم</label>
                <input type="text"  id="small-input" value={formData.username} name="username"  onChange={handleInputChange} class="block w-60 p-2 text-gray-700 border text-[14px] font-bold border-gray-300 rounded-md bg-gray-50 outline-none focus:shadow-md shadow-purple  focus:ring-primary focus:border-primary  dark:border-primary " />
                {errors.username && <span className="text-red-400 font-primary">{errors.username}</span>}
            </div>
            <div className="mx-4 my-2">
                <label for="small-input" class="block  text-sm font-primary mb-1 text-gray-900 dark:text-white">كلمة السر </label>
                <input type="password"   id="small-input" value={formData.password} name="password"  onChange={handleInputChange}  class="block w-full p-2 text-gray-700 border text-[14px] font-bold border-gray-300 rounded-md bg-gray-50 outline-none focus:shadow-md shadow-purple  focus:ring-primary focus:border-primary  dark:border-primary " /
                >
                     {errors.password && <span className="text-red-400 font-primary">{errors.password}</span>}<br></br>
                     {errors.password2 && <span className="text-red-400 font-primary">{errors.password2}</span>}<br></br>
                     {errors.password3 && <span className="text-red-400 font-primary">{errors.password3}</span>}<br></br>
                     {errors.password4 && <span className="text-red-400 font-primary">{errors.password4}</span>}<br></br>
                     {errors.password5 && <span className="text-red-400 font-primary">{errors.password5}</span>}
            </div>
            <div className="mx-4 my-2">
            <button type="submit" class=" block w-full text-white bg-primary p-2 border rounded-md hover:opacity-80 font-bold">تم</button>
            </div>
            <div className="mx-4 my-2">
            <button type="reset" class=" block w-full text-slate-700 bg-gray-100 p-2 border rounded-md hover:opacity-80 font-bold transition-all hover:bg-gray-600  hover:text-gray-50">إلغاء</button>
            </div>
            </form>
              </div>
        </>
    )
}

export default CreateService